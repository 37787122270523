<template>
  <module
    ref="module"
    id="overviewNew"
    titleIcon="nc-icon nc-layout-11"
    :title="$t('dashboard')"
    :use-default-list="false"
  >
    <div slot="global">
      <!-- Modal to notify user where to change setting -->
      <modal :show.sync="modalnotice" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">{{ $t('fyitxt') }}</h4>

        <div class="row">
          <div class="col-12">
            {{ modalmsg }}
          </div>
        </div>

        <template slot="footer">
          <p-button type="success" @click.prevent="redirectToAddAccount()" link> + {{ $t('addaccount') }}</p-button>
          <p-button type="info" v-if="hasValidatedAccounts" @click.prevent="redirectToFollow()" link>
            {{ $t('addstrategy') }}
          </p-button>
          <p-button type="info" v-else @click.prevent="checkDashMsg()" link>{{ $t('gotit') }}</p-button>
        </template>
      </modal>
      <!-- End Modal -->

      <!-- Modal for Adding Strategy -->
      <modal :show.sync="modaladdaccount" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">{{ $t('addaccount') }}</h4>
        <div :class="`alert-${modal_alert_type}`" class="alert alert-dismissible fade show" v-if="modal_alert">
          <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close"
                  @click.prevent="modal_alert = false">
            <i class="nc-icon nc-simple-remove"></i>
          </button>
          <span>{{ modal_alert_msg }}</span>
        </div>

        <div class="row">
          <div class="col-12">
            <el-select
              class="select-default form-control"
              v-model="accid"
              :placeholder="chooseaccount"
              style="margin-bottom: 10px;border: 0;">
              <el-option
                class="select-default"
                v-for="s in accounts"
                :key="s.id" :label="s.account" :value="s.id">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="row" v-if="accid">
          <div class="col-12">
            <span v-html="$t('byfollowingterms')"/>
          </div>
        </div>

        <div class="row" v-if="accid">
          <div class="col-sm-12 text-center">
            <p class="lead">
              <p-checkbox v-model="agree" class="label-check">{{ $t('iagreetxt') }}</p-checkbox>
            </p>
          </div>
        </div>

        <template slot="footer">
          <div class="py-3 mx-auto" v-if="modalinprogress">
            <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
          </div>
          <div class="left-side" v-if="modalinprogress == false" v-bind:disabled="!accid">
            <p-button @click.prevent="confirmAddAcc" link>{{ $t('addaccount') }}</p-button>
          </div>
          <div class="divider" v-if="modalinprogress == false"></div>
          <div class="right-side" v-if="modalinprogress == false">
            <p-button type="danger" @click.prevent="closeModalConfirmAcc" link>{{ $t('cancel') }}</p-button>
          </div>
        </template>
      </modal>
      <!-- End Modal -->

      <!-- Modal to Broker Info -->
      <modal :show.sync="modalbrokernotice" headerClasses="justify-content-center">
        <h5 slot="header" class="title title-up">{{ modal_title }}</h5>

        <div class="row" style="padding-bottom: 0;">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table">
                <tbody>

                <tr>
                  <td :style="(adminusr) ? '' : 'border-top: 0px;'">{{ $t('mainadvantages') }}</td>
                  <td class="text-right" :style="(adminusr) ? '' : 'border-top: 0px;'">
                    {{ brokerinfo.pros }}
                  </td>
                </tr>

                <tr>
                  <td>{{ $t('regulationtxt') }}</td>
                  <td class="text-right">
                    {{ brokerinfo.regulation }}
                  </td>
                </tr>

                <tr>
                  <td>{{ $t('compensationtxt') }}</td>
                  <td class="text-right">
                    {{ brokerinfo.compensation }}
                  </td>
                </tr>

                <tr>
                  <td>{{ $t('avg_eurusd_stdtxt') }}</td>
                  <td class="text-right">
                    {{ brokerinfo.avg_eurusd_std }}
                  </td>
                </tr>

                <tr>
                  <td>{{ $t('avg_eurusd_premtxt') }}</td>
                  <td class="text-right">
                    {{ brokerinfo.avg_eurusd_prem }}
                  </td>
                </tr>

                <tr>
                  <td>{{ $t('eurusd_swaptxt') }}</td>
                  <td class="text-right">
                    {{ brokerinfo.eurusd_swap }}
                  </td>
                </tr>

                <tr>
                  <td>{{ $t('freeswaptxt') }}</td>
                  <td class="text-right" :class="!brokerinfo.swaps ? 'text-danger' : 'text-success'">
                    <i class="nc-icon" :class="!brokerinfo.swaps ? 'nc-simple-remove' : 'nc-check-2'"
                       style="font-weight: bolder"></i>
                  </td>
                </tr>

                <tr>
                  <td>{{ $t('depmethtxt') }}</td>
                  <td class="text-right">
                    {{ brokerinfo.deposit }}
                  </td>
                </tr>


                <tr>
                  <td>{{ $t('freevps') }}</td>
                  <td class="text-right" :class="!brokerinfo.vps ? 'text-danger' : 'text-success'">
                    <i class="nc-icon" :class="!brokerinfo.vps ? 'nc-simple-remove' : 'nc-check-2'"
                       style="font-weight: bolder"></i>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('freecopier') }}</td>
                  <td class="text-right" :class="!brokerinfo.copier ? 'text-danger' : 'text-success'">
                    <i class="nc-icon" :class="!brokerinfo.copier ? 'nc-simple-remove' : 'nc-check-2'"
                       style="font-weight: bolder"></i>
                  </td>
                </tr>

                <tr>
                  <td>{{ $t('cashbacktxt') }}</td>
                  <td class="text-right">
                    {{ $t('uptotxt') }} ${{ brokerinfo.cashback }} {{ $t('per10lots') }}
                  </td>
                </tr>

                <tr>
                  <td colspan="2" :class="(adminusr) ? '' : 'text-center'">
                    <p-button type="info" @click.native="openwin(brokerinfo)">
                      {{ $t('openaccountbroker') }}
                    </p-button>
                  </td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <template slot="footer">
          <p-button type="info" @click.prevent="modalbrokernotice = false" link>{{ $t('gotit') }}</p-button>
        </template>
      </modal>
      <!-- End Modal -->

      <!-- Modal to notify user Telegram -->
      <modal :show.sync="cuzofdashmsg" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">{{ $t('fyitxt') }}</h4>

        <div class="row" v-if="modal_alert">
          <div class="col-12 text-center">
            <div :class="`alert-${modal_alert_type}`" class="alert alert-dismissible fade show">
              <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close"
                      v-on:click="modal_alert=false">
                <i class="nc-icon nc-simple-remove"></i>
              </button>
              <span>{{ modal_alert_msg }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            {{ dashmsg }}
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <el-select class="select" style="width: 100%;"
                       size="large" :placeholder="countryph"
                       v-model="mycountryph">
              <el-option v-for="option in countryphones"
                         class="select"
                         :value="option.code"
                         :label="option.name"
                         :key="option.code">
              </el-option>
            </el-select>
          </div>
          <div class="col-8">
            <fg-input v-model="umeta.phone" addon-left-icon="nc-icon nc-mobile"
                      :placeholder="phoneTxt" type="number" :name="phoneTxt"></fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label>{{ $t('user_lang') }}</label>
            <el-select class="select" style="width: 100%;"
                       size="large" :placeholder="userlang"
                       v-model="umeta.lang">
              <el-option v-for="option in languages"
                         class="select"
                         :value="option.code"
                         :label="option.name"
                         :key="option.code">
              </el-option>
            </el-select>
          </div>
        </div>


        <template slot="footer">
          <div class="py-3 mx-auto" v-if="modalinprogress">
            <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
          </div>
          <div class="left-side" v-if="!modalinprogress">
            <p-button @click.prevent="editProfile()" link>{{ $t('edit_profile') }}</p-button>
          </div>
          <div class="divider" v-if="!modalinprogress"></div>
          <div class="right-side" v-if="!modalinprogress">
            <p-button type="danger" @click.prevent="cancelModal()" link>{{ $t('cancel') }}</p-button>
          </div>
        </template>
      </modal>
      <!-- End Modal -->

      <!-- Modal Earn More-->
      <modal :show.sync="modalearn" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">{{ $t('earnmoretxt') }}</h4>
        <div :class="`alert-${modal_alert_type}`" class="alert alert-dismissible fade show" v-if="modal_alert">
          <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close"
                  v-on:click="modal_alert=false">
            <i class="nc-icon nc-simple-remove"></i>
          </button>
          <span>{{ modal_alert_msg }}</span>
        </div>

        <div class="row">
          <div class="col-12">
            {{ $t('earnmoredesctxt') }}
          </div>
        </div>

        <div class="row">
          <div class="col-12">&nbsp;</div>
        </div>

        <div class="row">
          <div class="col-md-6" v-for="a in accountearnmore">
            <p-checkbox v-model="a.selected">
              <div class="earn-more-broker-logo earn-more">
                <img v-if="a.logo && a.logo != 'null'"
                     :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${a.logo}.png`">
                <img v-else alt="Broker">
              </div>
              <span>{{ a.account }}</span>
            </p-checkbox>
          </div>
        </div>

        <template slot="footer">
          <div class="py-3 mx-auto" v-if="modalinprogress">
            <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
          </div>
          <div class="left-side" v-if="modalinprogress == false">
            <p-button @click.prevent="sendRequest()" link>{{ $t('sendrequest') }}</p-button>
          </div>
          <div class="divider" v-if="modalinprogress == false"></div>
          <div class="right-side" v-if="modalinprogress == false">
            <p-button type="danger" @click.prevent="cancelEarnMoreModal()" link>{{ $t('cancel') }}</p-button>
          </div>
        </template>
      </modal>
      <!-- End Modal-->

      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div class="row" v-if="!inprogress">

        <div id="freeTradeReplication" class="col-lg-3 col-md-4 col-sm-4"
             v-if="statsBroker.length > 0 && !hide_free_replication">
          <div class="card">
            <div class="card-header">
              <h5>{{ $t('freecopier') }}</h5>
            </div>
            <div class="card-body">
              <div class="row">

                <div class="col-md-4 text-center" v-for="broker in statsBroker">
                  <div class="brokerlogo">
                    <img @click="openwin(broker)"
                         :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${broker.logo}.png` ">
                    <!--                  <p-button type="info" icon round @click.native="morebrokerinfo(`${broker.id}`)" class="bit-smaller">-->
                    <!--                    <i class="fa fa-info"></i>-->
                    <!--                  </p-button>-->
                  </div>
                  <h5>{{ broker.name }}</h5>
                </div>

              </div>
            </div>
            <div class="card-footer">

            </div>
          </div>

        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="card">
            <div class="card-header" style="top: 5px;position: relative;">
              <div class="pull-left" style="color: #4cbdd7;">
                <span style="color: #252422;">{{ $t('balance') }}</span>
                <h5>${{ performance.bal }}</h5>
              </div>
              <div class="pull-right" style="color: #ef8157;">
                <span style="color: #252422;">{{ $t('equity') }}</span>
                <h5>${{ performance.equity }}</h5>
              </div>
            </div>

            <div class="card-body row">
              <div class="col-md-12">
                <line-chart :height="150"
                            :labels="performance.labels"
                            :extra-options="performance.options"
                            :datasets="performance.datasets">
                </line-chart>
              </div>

              <div class="col-md-12">&nbsp;</div>

              <div class="col-md-12">

                <table class="table">
                  <tbody>
                  <tr>
                    <td>
                    <span style="padding-left: 10px">
                      <i class="fa fa-bar-chart-o text-info"></i>
                      {{ $t('total_accounts_txt') }}
                    </span>
                    </td>
                    <td class="text-right">
                      {{ performance.accounts }}
                    </td>
                  </tr>


                  <tr>
                    <td>
                    <span style="padding-left: 10px">
                      <i class="fa fa-money text-success"></i>
                      {{ $t('sponsored_accounts_txt') }}
                    </span>
                    </td>
                    <td class="text-right">
                      {{ performance.freeCopier }}
                    </td>
                  </tr>

                  <tr>
                    <td>
                    <span style="padding-left: 10px">
                      <i class="fa fa-star text-warning"></i>
                      {{ $t('master_accs') }}
                    </span>
                    </td>
                    <td class="text-right">
                      {{ performance.masterAccounts }}
                    </td>
                  </tr>

<!--                  <tr v-if="performance.premiumPossible && !hide_premium">-->
<!--                    <td>-->
<!--                      <p-button link type="danger" @click.prevent="earnMore()">-->
<!--                        <i class="fa fa-exclamation-circle"></i>-->
<!--                        {{ $t('upgradeable_accounts_txt') }}-->
<!--                      </p-button>-->
<!--                    </td>-->
<!--                    <td class="text-right">-->
<!--                      {{ performance.premiumPossible }}-->
<!--                    </td>-->
<!--                  </tr>-->

                  </tbody>
                </table>

              </div>
            </div>

            <hr/>

            <div class="card-footer" style="top: -10px;position: relative;">
              <i class="fa fa-info-circle"></i> {{ $t('last30_accounts_txt') }}
            </div>

          </div>

        </div>

      </div>

    </div>
  </module>
</template>
<script>
  import Vue from 'vue';
  import Storage from 'vue-web-storage';

  import { Modal } from 'src/components/UIComponents'
  import StatsCard from 'src/components/UIComponents/Cards/StatsCardDash';
  import { Tooltip, Select, Option } from 'element-ui'

  import LineChart from 'src/components/UIComponents/Charts/LineChart';
  import StrategyChart from 'src/components/Dashboard/Views/Components/StrategyChart';
  import StrategyStats from 'src/components/Dashboard/Views/Components/StrategyStat';
  import Constants from 'src/assets/constants'
  import swal from "sweetalert2";
  const rules = Constants.methods.getRules()

  Vue.use(Storage, {
    prefix: 'hokocloud_',// default `app_`
    drivers: ['session'], // default 'local'
  });

  const user_data = JSON.parse(localStorage.getItem('user-info'));
  const timercountdown = 1500;
  const timeInitLoad = 1000;

  const maxSteps = 10;
  const subLength = 175;
  const maxLength = 180;
  const maxNameLength = 32;
  const subNameLength = 28;

  const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
  };

  export default {
    components: {
      StatsCard, Modal, [Tooltip.name]: Tooltip, LineChart,
      [Option.name]: Option, [Select.name]: Select
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {
        statsCards: [],
        freeVPSInfo: [],
        showFreeVPSStat: false,
        modalnotice: false,
        modalmsg: null,
        dashmsg: null,
        cuzofdashmsg: false,
        countryphones: [],
        countryph: this.$t('country') +" "+ this.$t('codetxt'),
        phoneTxt: this.$t('phone'),
        userlang: this.$t('user_lang'),
        languages: [
          { 'code': 'en', 'name': 'English'},
          { 'code': 'pt', 'name': 'Portuguese' },
          { 'code': 'it', 'name': 'Italian'},
          { 'code': 'sp', 'name': 'Spanish' },
          { 'code': 'fr', 'name': 'French'},
          { 'code': 'ru', 'name': 'Russian' },
          { 'code': 'ro', 'name': 'Romanian'}
        ],
        modalinprogress: false,
        modal_alert: false,
        modal_alert_msg: null,
        modal_alert_type: 'danger',
        telegverified: false,
        umeta: {  },
        mycountryph: 'US_1',
        statsBal: {},
        performancefee: this.$t('performancefee'),
        fixedfee: this.$t('fixedfee'),
        marketplaceChart: {
          labels: {},
          datasets: {},
          options: {},
          loading: {},
          stats: {},
          accounts: {}
        },
        firstloadchart: false,
        statsBroker: [],
        modalbrokernotice: false,
        modal_title: null,
        adminusr: false,
        brokerinfo: {},
        inprogress: true,
        modaladdaccount: false,
        msid: null,
        chooseaccount: this.$t('chooseaccount'),
        accid: null,
        accounts: [],
        agree: false,
        allbrokers: [],
        performance: {
          labels: [],
          bal: 0,
          equity: 0,
          freeCopier: 0,
          accounts: 0,
          premium: 0,
          premiumPossible: 0,
          regularAccounts: 0,
          datasets: [
            {
              label: this.$t('balance'),
              borderColor: '#4cbdd7',
              fill: false,
              backgroundColor: '#4cbdd7',
              hoverBorderColor: '#4cbdd7',
              borderWidth: 2,
              pointRadius: 0,
              data: [],
            },
            {
              label: this.$t('equity'),
              borderColor: '#ef8157',
              fill: false,
              backgroundColor: '#ef8157',
              hoverBorderColor: '#ef8157',
              borderWidth: 2,
              pointRadius: 0,
              data: [],
            }
          ],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  callback: function(value, index, values) {
                    let val = parseFloat(value / 1000).toFixed(1);
                    return val +'k';
                  }
                },
                gridLines: { display: true }
              }],
              xAxes: [{
                time: {
                  unit: 'day',
                  stepSize: 3
                },
                type: 'time', ticks: {  },
                gridLines: { display: true }
              }]
            }
          }
        },
        inprogresstxt: this.$t('loadingtxt') +" "+ this.$t('dashboard')+". "+this.$t('pleasewait'),
        hide_free_replication: false,
        hide_premium: false,
        modalearn: false,
        potentialPremium: [],
        accountearnmore: [],
        atleastone: false,
        partnerbrokers: [],
        hasValidatedAccounts: false
      }
    },
    methods: {
      addStrategies(aid) {
        this.$getPublicStrategy(aid)
          .then(this.loadAccount, this.loadManually);
      },
      openwin(broker) {
        if(broker.link === 'N/A' || broker.link === null) {
          return
        }
        window.open(broker.wlUrl ? broker.wlUrl  : broker.link, '_blank');
        let json_data = {
          'broker_name': broker.name,
          'broker_link': broker.link,
          'email': user_data.email,
          'first_name': user_data.user_metadata.first_name,
          'last_name': user_data.user_metadata.last_name
        }
        this.$sendPartnerBrokerEmail(json_data)
      },
      loadPerformance(resp) {
        this.performance.masterAccounts = resp.data.masterAccounts
        this.performance.accounts = resp.data.accounts;
        this.performance.freeCopier = resp.data.freeCopier;
        this.performance.premium = resp.data.premium;
        this.performance.premiumPossible = resp.data.premiumPossible;
        this.performance.regularAccounts = resp.data.regularAccounts;

        for(var i=0;i < resp.data.performance.length;i++) {
          const p = resp.data.performance[i];
          this.performance.labels.push(p.dt);
          this.performance.datasets[0].data.push(p.balance);
          this.performance.datasets[1].data.push(p.equity);
        }
        this.performance.bal = this.performance.datasets[0].data[this.performance.datasets[0].data.length - 1];
        this.performance.equity = this.performance.datasets[1].data[this.performance.datasets[1].data.length - 1];

        this.inprogress = false;
      },
      loadBrokers(resp) {
        let brokers = [];
        if (!resp.data.length) {
          return
        }
        for (var i=resp.data.length-1;i >= 0;i--) {
          if (resp.data[i].copier) {
             this.allbrokers.push(resp.data[i]);
             this.statsBroker.push(resp.data[i]);
          }
        }
        this.statsBroker.sort(this.sortIt);
      },
      sortIt(a, b) {
        if (a['sort_order'] < b['sort_order']){
          return -1
        } else {
          return 1
        }
      },
      morebrokerinfo(id) {
        const bidx = this.allbrokers.findIndex(ab => ab.id === parseInt(id));

        this.modal_title = this.allbrokers[bidx].name;
        this.brokerinfo = Object.assign({}, this.allbrokers[bidx]);
        this.modalbrokernotice = true;
      },
      loadAccounts(resp) {
        const now = this.$moment('timezone', 'Europe/London', 'LLLL ss').unix();

        this.accounts = [];

        for(var i=0;i < resp.data.validated.length;i++) {
          const verified = resp.data.validated[i];
          if (verified.strategies === undefined || verified.strategies === null || verified.strategies.length === 0) {
            this.accounts.push(verified);
          }
        }
        this.modaladdaccount = true;
      },
      getAccounts() {
        // Get Accounts
        this.$getAllAccounts_v2({
          eml: user_data['email'],
        }).then(this.loadAccounts, this.failop);
      },
      closeModalConfirmAcc() {
        this.modaladdaccount = false;
        this.modal_alert  = false;
        this.modal_alert_msg = null;
        this.modal_alert_type = 'danger';
        this.accid = null;
        this.msid = null;
        this.agree = false;

        const sid = this.statsCards[0].sid;
        this.$refs[sid +"_buttons"][0].classList.remove("hide-me");
        this.$refs[sid +"_loader_buttons"][0].classList.add("hide-me");
      },
      postAddAcc() {
        this.closeModalConfirmAcc();
      },
      accountAdded() {
        this.modal_alert  = true;
        this.modal_alert_msg = this.$t('followeradded');
        this.modal_alert_type = 'success';
        this.agree = false;

        const sid = this.statsCards[0].sid;
        // Followed
        this.statsCards[0].followed = true;
        // TimeOut
        setTimeout(this.postAddAcc, timercountdown);
      },
      confirmAddAcc() {
        this.modal_alert  = false;

        if (this.accid === null || this.accid === undefined) {
          this.closeModalConfirmAcc();
        }
        else {
          if (!this.agree) {
            this.modal_alert  = true;
            this.modal_alert_msg = this.$t('tandaneeded');
            this.modal_alert_type = 'danger';
          }
          else {
            this.modalinprogress = true;
            this.$delsertFollower(this.msid, this.accid, false).then(this.accountAdded, this.failop);
          }
        }
      },
      addAccount(sid) {
        this.msid = sid;
        this.$refs[sid +"_buttons"][0].classList.add("hide-me");
        this.$refs[sid +"_loader_buttons"][0].classList.remove("hide-me");
        this.getAccounts();
      },
      doneWatching(resp) {
        const sid = this.statsCards[0].sid;
        this.$refs[sid +"_buttons"][0].classList.remove("hide-me");
        this.$refs[sid +"_loader_buttons"][0].classList.add("hide-me");
        // Watched
        this.statsCards[0].watching = true;
      },
      watchStrategy(sid) {
        this.loadingtxt = this.$t('addingwatchlist');
        //this.inprogress = true;
        //this.watchlistbop = sid;
        this.$refs[sid +"_buttons"][0].classList.add("hide-me");
        this.$refs[sid +"_loader_buttons"][0].classList.remove("hide-me");

        this.$delsertStrategy(user_data['email'], sid).then(this.doneWatching, this.failop);
      },
      cancelModal() {
        this.cuzofdashmsg = false;
        this.dashmsg = null;
      },
      more(sid) {
        const timebtwnchart = 10;
        // Get Account Number
        //let account = this.marketplaceChart.accounts[sid];
        let sidx = this.statsCards.findIndex(st => st.sid == sid);
        let sname = null;
        let sdesc = null;

        if (sidx >= 0) {
          sname = this.statsCards[sidx].name;
          sdesc = this.statsCards[sidx].raw_desc;
          let account = this.marketplaceChart.accounts[sid];

          localStorage.setItem('_tmp-sid-account', JSON.stringify({
            sid: sid, sname: sname, sdesc: sdesc, account: account
          }));
          if (this.adminusr) {
            location.href = "/admin_strategies/info";
          }
          else {
            location.href = "/strategies/info";
          }
        }
        else {
          const _tryAgain = n => {
            this.more(sid);
          };
          setTimeout(_tryAgain, timebtwnchart * 5);
        }
      },
      _openChart(mplaceinfo, strat_id, nidx) {
        let instance = this.$setStrategyChartInstance(StrategyChart, mplaceinfo);
        let loader = strat_id +"_loader";
        let cont = strat_id +"_container";
        // Account
        this.marketplaceChart.accounts[strat_id] = mplaceinfo.account;

        if (this.$refs[loader][0] !== undefined) {
          this.$refs[loader][0].classList.add("hide-me");
        }
        if (this.marketplaceChart.datasets[strat_id].length == 0) {
          instance.$slots.default = [ this.$t('nodatatxt') ];
        }
        instance.$mount(); // pass nothing
        if (this.$refs[cont][0].firstChild) {
          this.$refs[cont][0].firstChild.remove();
        }
        this.$refs[cont][0].appendChild(instance.$el);
        // This is a hack, should be a better way to do this
        if (!this.firstloadchart) {
          let instance2 = this.$setStrategyChartInstance(StrategyChart, mplaceinfo);
          instance2.$mount(); // pass nothing
          this.$refs[cont][0].firstChild.remove();
          this.$refs[cont][0].appendChild(instance2.$el);
          this.firstloadchart = true;
        }
        // Statistics
        let instanceStat = this.$setStrategyStatsInstance(StrategyStats, { stats: mplaceinfo.stats });
        instanceStat.$mount(); // pass nothing
        this.$refs[loader +"_stat"][0].classList.add("hide-me");
        if (this.$refs[cont +"_stat"][0].firstChild) {
          this.$refs[cont +"_stat"][0].firstChild.remove();
        }
        this.$refs[cont +"_stat"][0].appendChild(instanceStat.$el);
        // Button
        this.$refs[strat_id +"_infobtn"][0].$el.disabled = false;
      },
      chartFailedLoaded(err, r) {
        const loader = r.strat_id +"_loader"; //this.clonedStrategies[resp.next_idx].sid +"_loader"
        const cont = r.strat_id +"_container"; //this.clonedStrategies[resp.next_idx].sid +"_container";
        this.$refs[loader][0].classList.add("hide-me");
        this.$refs[loader +"_stat"][0].classList.add("hide-me");

        // Empty Charts
        if (this.$refs[cont][0] !== undefined && this.$refs[cont][0].firstChild) {
          //this.$refs[cont][0].firstChild.remove();
        }
        else {
          let instance = this.$setStrategyChartInstance(StrategyChart, { labels: [], data: [], options: [], info: {} });
          instance.$slots.default = [ this.$t('nodatatxt') ];
          instance.$mount(); // pass nothing
          this.$refs[cont][0].appendChild(instance.$el);
        }
        // Empty Statistics
        if (this.$refs[cont +"_stat"][0] !== undefined && this.$refs[cont +"_stat"][0].firstChild) {
          //this.$refs[cont][0].firstChild.remove();
        }
        else {
          let instanceStat = this.$setStrategyStatsInstance(StrategyStats, { stats: {} })
          instanceStat.$mount();
          this.$refs[cont +"_stat"][0].appendChild(instanceStat.$el);
        }
      },
      _chartLoad(resp) {
        let strat_id = resp.strat_id;
        // Account
        this.marketplaceChart.accounts[strat_id] = resp.account;
        for(var a=0;a < resp.plot.length;a++) {
          let p = resp.plot[a];
          this.marketplaceChart.labels[strat_id].push(p.unix_dt * 1000);
          //this.marketplaceChart.datasets[strat_id].push((p.accumulatedBalance * 100).toFixed(2));
          this.marketplaceChart.datasets[strat_id].push((p.accumulatedEquityGrowth * 100).toFixed(2));
        }
        if (this.marketplaceChart.datasets[strat_id].length > maxSteps) {
          let stepSize = parseInt(this.marketplaceChart.datasets[strat_id].length / maxSteps);
          this.marketplaceChart.options[strat_id].scales.xAxes[0].time.stepSize = stepSize;
        }
        this.marketplaceChart.stats[strat_id] = resp.stats;
        this.marketplaceChart.loading[strat_id] = false;
        // Data to be saved
        const mplaceinfo = {
          account: resp.account,
          labels: this.marketplaceChart.labels[strat_id],
          data: this.marketplaceChart.datasets[strat_id],
          options: this.marketplaceChart.options[strat_id],
          info: {
            "lastupdate": this.$t('lastupdate') +": "+ resp.lastupdate,
            "orders_fail": resp.orders_fail,
            "symbol_price_fail": resp.symbol_price_fail,
            "trade_history_fail": resp.trade_history_fail
          },
          reporting_status: this.$getChartFlagInfo(resp),
          stats: this.marketplaceChart.stats[strat_id],
          expiry: this.$moment('timezone', 'Europe/London', 'LLLL ss').add(15, 'm').unix()
        };
        localStorage.setItem('hc_mplace_'+ strat_id, JSON.stringify(mplaceinfo));
        // Charts
        this._openChart(mplaceinfo, strat_id, resp.next_idx);
      },
      chartLoaded(resp) {
        if (!resp.success) {
          chartFailed(resp.msg, resp);
          return;
        }
        const chartFailed = (err, r) => {
          this.chartFailedLoaded(err, r.data);
        };
        const loadNext = n => {
          this.$loadStratChart(this.clonedStrategies[resp.data.next_idx].sid, resp.data.next_idx+1)
            .then(this.chartLoaded, err => { chartFailed(err, resp); });
        };
        // Load Chart
        this._chartLoad(resp.data);
        this.loadCharts(resp.data.next_idx+1);
      },
      loadCharts(idx) {
        // Only Process available strats
        if (idx < this.statsCards.length) {
          const sid = this.statsCards[idx].sid;
          const now = this.$moment('timezone', 'Europe/London', 'LLLL ss').unix();
          let mplaceinfo = localStorage.getItem('hc_mplace_'+ sid);
          let existLocal = false;

          if (mplaceinfo) {
            mplaceinfo = JSON.parse(mplaceinfo);
            if (now <= mplaceinfo.expiry) {
              mplaceinfo.options.scales.yAxes[0].ticks = {
                callback: function(value, index, values) {
                  return value +'%';
                }
              };
              existLocal = true;
            }
          }

          if (existLocal) { // If local strat marketplace exists
            this._openChart(mplaceinfo, sid, idx);
            this.loadCharts(idx+1);
          }
          else { // If it does not
            const chartFailed = (err, r) => {
              this.chartFailedLoaded(err, r);
              this.loadCharts(idx+1);
            };
            this.$loadStratChart(sid, idx).then(this.chartLoaded, function(err) {
              const r = { 'strat_id': sid };
              chartFailed(err, r);
            });
          }
        }
        else {
          this.searchenable = true;
        }
      },
      loadStrategies(resp, cached) {
        const now = this.$moment('timezone', 'Europe/London', 'LLLL ss').unix();

        this.rawStrats = {};
        this.statsCards = [];

        let strats = resp.strategies;
        /*if (this.watchlistonly) {
          strats = resp.watchlist;
        }*/
        if (cached) {
          this.rawStrats = Object.assign({}, strats);
        }
        else {
          for(var i=0;i < strats.length;i++) {
            let strat = strats[i];
            //const exist = this.ownstrats.findIndex(os => os.strat_id === strat.strat_id);
            //if (exist < 0 && strat.underManagement !== null) {
            if (!(strat.strat_id in this.rawStrats)) {
              this.rawStrats[strat.strat_id] = [];
            }
            this.rawStrats[strat.strat_id].push(strat);
            //}
          }
        }

        // Cached?
        if (cached === undefined || cached === null || !cached) {
          const marketplace = {
            strategies: this.rawStrats,
            expiry: this.$moment('timezone', 'Europe/London', 'LLLL ss').add(15, 'm').unix()
          };
          localStorage.setItem('hc_marketplace_all', JSON.stringify(marketplace));
        }

        // Randomize, get one only
        const sids = Object.keys(this.rawStrats);
        const max = sids.length - 1;
        const min = 0;
        const sid = parseInt(Math.random() * (+max - +min) + +min);
        const strat = this.rawStrats[sids[sid]];
        const slen = strat.length;
        const mexist = strat.findIndex(s => s.acc_type === 'master');

        let master = false;
        let follower = true;
        let discoverable = false;
        let desc = strat[0].strat_desc;

        if (mexist >= 0) {
          master = true;
        }
        if (slen > 0) {
          if (master && slen > 1) {
            follower = true;
          }
          if (master && slen === 1) {
            follower = false;
          }
        }

        if (strat[0].discoverable) {
          discoverable = strat[0].discoverable;
        }

        if (desc && desc.length > maxLength) {
          desc = desc.substring(0, subLength) +" ...";
        }

        let sname = strat[0].strat_name;
        if (sname > maxNameLength) {
          sname = sname.substring(0, subNameLength) +"...";
        }
        let strategy = {
          'name': sname,
          'url': strat[0].strat_url,
          'desc': desc,
          'sid': strat[0].strat_id,
          'raw_desc': strat[0].strat_desc,
          'count': slen,
          'master': master,
          'follower': follower,
          'public': true,
          'canDelete': false,
          'followed': false,
          'price': strat[0].price * 100,
          'watching': false,
          'daysSinceInception': strat[0].daysSinceInception
        };
        if (master && !follower) {
          strategy['canDelete'] = true;
        }

        // Add to Dataset
        this.marketplaceChart.loading[strategy.sid] = true;
        this.marketplaceChart.accounts[strategy.sid] = null;
        this.marketplaceChart.stats[strategy.sid] = {};
        this.marketplaceChart.labels[strategy.sid] = [];
        this.marketplaceChart.datasets[strategy.sid] = [];
        this.marketplaceChart.options[strategy.sid] = {
          tooltips: tooltipOptions,
          scales: {
            yAxes: [{
              ticks: {
                callback: function(value, index, values) {
                  return value +'%';
                }
              },
              gridLines: { display: true }
            }],
            xAxes: [{
              time: {
                unit: 'day',
                stepSize: 1
              },
              type: 'time', ticks: {  },
              gridLines: { display: true }
            }]
          }
        };

        this.statsCards.push(strategy);
        const _loadCharts = n => {
          this.loadCharts(0);
        };

        this.inprogress = false;
        setTimeout(_loadCharts, timeInitLoad);
      },
      preloadStrategies(resp) {
        this.ownstrats = this.ownstrats.concat(resp.data);

        const now = this.$moment('timezone', 'Europe/London', 'LLLL ss').unix();
        let mpstrats = localStorage.getItem('hc_marketplace_all');
        let cached = false;

        if (mpstrats !== null && mpstrats !== undefined) {
          mpstrats = JSON.parse(mpstrats);
          if (now <= mpstrats.expiry) {
            cached = true;
            this.loadStrategies(mpstrats, true);
          }
        }
        // Not Cached
        if (!cached) {
          this.$getMarketStrategies_v2().then(this.loadStrategies, this.failop);
        }
      },
      getMarketPlace(resp) {
        this.ownstrats = [];
        this.ownstrats = resp.data.slice(0);

        // Get Watchlist
        this.$getWatchlist_v2({eml: user_data['email']})
          .then(this.preloadStrategies, this.failop);
      },
      getOwnStrategies() {
        this.$getStrategies(user_data['email']).then(this.getMarketPlace, this.failop);
      },
      checkDashMsg() {
        this.modalnotice = false;
        const ls_cuzofdashmsg = localStorage.getItem('hc_cuzofdashmsg');
        if (this.dashmsg !== null && (ls_cuzofdashmsg === undefined || ls_cuzofdashmsg === null)) {
          this.cuzofdashmsg = true;
          localStorage.setItem('hc_cuzofdashmsg', true);
        }
      },
      closeDashMsg() {
        this.cuzofdashmsg = false;
        this.modal_alert = false;
        this.modal_alert_msg = null;
        this.modal_alert_type = 'danger';
      },
      redirectToAddAccount() {
        window.location = '/accounts/main'
      },
      redirectToFollow() {
        window.location = '/strategies/watchlist'
      },
      editProfileFail(err) {
        this.modal_alert = true;
        this.modal_alert_msg = this.$t('userinfoup_fail');
        this.modal_alert_type = 'danger';
      },
      profileEdited(resp) {
        this.modalinprogress = false;
        this.modal_alert = true;
        this.modal_alert_msg = this.$t('userinfoup_success');
        this.modal_alert_type = 'success';
        // Edit Local Storage
        let uinfo = Object.assign({}, user_data);
        uinfo['user_metadata'] = Object.assign({}, this.umeta);
        localStorage.setItem('user-info', JSON.stringify(uinfo));
        // Close byCountDown
        setTimeout(this.closeDashMsg, timercountdown);
      },
      editProfile() {
        this.modalinprogress = true;
        this.modal_alert = false;
        // Copy value
        this.umeta.countryph = this.mycountryph;

        let json_data = Object.assign({}, this.umeta);

        const ph = this.umeta.phone;
        const cph = this.umeta.countryph.split("_");

        json_data['phone'] = "+"+ cph[1] + ph;
        json_data['eml'] = user_data.email;
        if (ph !== user_data.user_metadata.phone) {
          json_data['modphone'] = true;
        }
        this.$updateUserInfo(user_data.user_id, json_data).then(this.profileEdited, this.editProfileFail);
      },
      loadAccount(resp) {
        const ses = resp.account.strategies;

        const failedAdd = (error, idx) => {
          let def = true;
          if (error.data) {
            if (error.data.msg) {
              if (error.data.msg.match(/Duplicate entry/gi) !== null) {
                def = false;
              }
            }
          }

          if (def) {
            this.modalnotice = true;
            this.modalmsg = this.$t('failedstrategytowatchlist');
          }
          else {
            idx += 1;
            addStrat(idx);
          }
        };

        const addStrat = idx => {
          if (idx == ses.length) {
            this.notifyStrategyAdded();
          }
          else {
            const sid = ses[idx].strat_id;
            this.$delsertStrategy(user_data.email, sid).then(function() {
              idx += 1;
              addStrat(idx);
            }, function(error) {
              failedAdd(error, idx);
            });
          }
        };

        addStrat(0);
      },
      loadedAccountsToWatchlist(resp) {
        let aid = Vue.$sessionStorage.get('acc_id');
        if (aid) {
          this.notifyStrategyAdded();
        }
      },
      loadFail(error) {
      },
      loadProjections(resp) {
        if (!resp.success) {
          // TODO ADD WARNING
          // TODO WARN:LOAD:PROJECTIONS
          return;
        }
        let data = resp.data;

        this.statsBal = {
          type: 'info',
          icon: 'nc-icon nc-money-coins',
          title: this.$t('currentbalancealt'),
          value: data.balance +" HKO",
          balance: parseFloat(data.balance),
          footerText: this.$t('currentbalancealt_footer'),
          footerIcon: 'nc-icon nc-alert-circle-i'
        };
      },
      loadVPSStat(resp) {
        if (Object.keys(resp).length === 0) {
          this.showFreeVPSStat = false;
        }
        else {
          let sessthisweek = (parseFloat(resp.session_count) / parseFloat(resp.max_session_count)) * 100;
          let daysthisweek = (parseFloat(resp.session_days) / parseFloat(resp.max_session_days)) * 100;
          let consecweeks = (parseFloat(resp.consecutive_weeks) / parseFloat(resp.max_consecutive_weeks)) * 100;
          let totalweeks = (parseFloat(resp.failed_weeks) / parseFloat(resp.max_failed_weeks)) * 100;

          const sess = {
            percentage: parseInt(sessthisweek), description: this.$t('sessionsthisweek_desc'),
            title: this.$t('sessionsthisweek'), color: "#4acccd",
            info: resp.req_session_count + this.$t('sessionsthisweek_req')
          };
          this.freeVPSInfo.push(sess);

          const days = {
            percentage: parseInt(daysthisweek), description: this.$t('daysthisweek_desc'),
            title: this.$t('daysthisweek'), color: "#fcc468",
            info: resp.req_session_days + this.$t('daysthisweek_req')
          };
          this.freeVPSInfo.push(days);

          const consec = {
            percentage: parseInt(consecweeks), description: this.$t('consecutiveweeksfailed_desc'),
            title: this.$t('consecutiveweeksfailed'), color: "#f17e5d",
            info: resp.alw_consecutive_weeks + this.$t('consecutiveweeksfailed_allowed')
          };
          this.freeVPSInfo.push(consec);

          const total = {
            percentage: parseInt(totalweeks), description: this.$t('totalweeksfailed_desc'),
            title: this.$t('totalweeksfailed'), color: "#66615b",
            info: resp.alw_failed_weeks + this.$t('totalweeksfailed_allowed')
          };
          this.freeVPSInfo.push(total);
          // Show VPS Stats
          this.showFreeVPSStat = true;
        }
      },
      notifyStrategyAdded() {
        Vue.$sessionStorage.remove('strat_id')
        if (user_data.user_metadata.strat_id) {
          delete user_data.user_metadata.strat_id;
          // Store into localStorage
          localStorage.setItem('user-info', JSON.stringify(user_data));
        }
        if (user_data.user_metadata.acc_id) {
          delete user_data.user_metadata.acc_id;
          // Store into localStorage
          localStorage.setItem('user-info', JSON.stringify(user_data));
        }
        this.modalnotice = true;
        this.modalmsg = this.$t('addedstrategytowatchlist');
      },
      loadManually(error) {
        let def = true;
        if (error.data) {
          if (error.data.msg) {
            if (error.data.msg.match(/Duplicate entry/gi) !== null) {
              def = false;
            }
          }
        }

        if (def) {
          this.modalnotice = true;
          this.modalmsg = this.$t('failedstrategytowatchlist');
        }
        else {
          this.notifyStrategyAdded();
        }
      },
      loadDashMsg(resp) {
        if (!resp.success) {
          return;
        }
        let data = resp.data[0];
        this.telegverified = data ? data.telegramVerified : -1;
        // Phone Logic
        if (this.umeta.countryph || this.umeta.countryph) {
          if (this.telegverified < 1) {
            this.umeta.countryph = "US_1";
          }
        }
        // Parse Phone
        const ccode = this.umeta.countryph ? this.umeta.countryph.split("_") : ''
        if (this.umeta.phone && this.umeta.phone && this.umeta.phone && this.telegverified < 1) {
          this.umeta.phone = this.umeta.phone.replace("+"+ ccode[1], "");
        }
        // Show as notice
        if ((user_data.user_metadata.subteleg || user_data.user_metadata.subteleg) && this.telegramVerified == 0) {
          this.dashmsg = this.$t('subscribemetotelegramwarning');
          if (this.modalnotice === false) {
            this.checkDashMsg();
          }
        }
        // Get Brokers
        this.$getPartnerBrokers_v2({
          user: true,
          copier: true
        }).then(this.loadBrokers, this.failop);

        this.$getPartnerBrokers_v2({
          user: true
        }).then(this.loadedPartnerBrokers, this.failop);

        this.$getPerformance(user_data.email)
          .then(this.loadPerformance, this.failop);

        this.$getAllAccounts_v2({eml: user_data.email})
            .then(this.loadAccountsRaw, this.failop);

      },
      loadedPartnerBrokers(resp){
        this.partnerbrokers = resp.data.slice(0);
      },
      loadAccountsRaw(response) {
        for (var i=0;i < response.data.validated.length;i++) {
          let verified = response.data.validated[i];
          this.hasValidatedAccounts = true;
          if( verified.brokerCashback_possible && !verified.demo ) {
            this.potentialPremium.push(verified);
          }
        }
      },
      failop(response) {
        console.error(response);
      },
      _parseEarnMore(accInfo, openModal) {
        const bidx = this.partnerbrokers.findIndex(b => b.name.toLowerCase() === accInfo.broker.toLowerCase());
        const bInfo = this.partnerbrokers[bidx];
        if (!(accInfo.account in this.accountearnmore)) {
          this.accountearnmore.push({
            'account': accInfo.account,
            'cashback': bInfo.cashback,
            'primaryeml': bInfo.primaryeml,
            'secondary': bInfo.secondary,
            'broker': bInfo.name,
            'logo': bInfo.logo,
            'link': bInfo.link,
            'selected': true
          });
        }
        // Open Modal
        if (openModal) {
          this.modalearn = openModal;
        }
      },
      earnMore() {
        let openModal = false;
        this.accountearnmore = [];

        for (var i = 0; i < this.potentialPremium.length; i++) {
          let accInfo = this.potentialPremium[i];
          if (!(accInfo.isSuperIB) && accInfo.brokerCashback_possible) {
            this._parseEarnMore(accInfo, false);
          }
        }
        if (i === this.potentialPremium.length) {
          this.accountearnmore.sort(function (a, b) {
            if (a.broker == b.broker) {
              return 0;
            }
            return a.broker > b.broker ? 1 : -1;
          })
          this.modalearn = true;
        }
      },
      cancelEarnMoreModal() {
        this.modalearn = false;
        this.modalinprogress = false
      },
      sendRequest(){
        this.modalinprogress = true;
        this.modal_alert = false;
        this.sendEmailToBroker(0)
      },
      failopSenteEarnMore(error) {
        if (error.data !== undefined) {
          error = error.data.msg;
        }
        this.modalinprogress = false;
        // this.$toast.error(this.$t(rdpdownmsg));
        this.$toast.error(this.$t(error));
      },
      sendEmailToBroker(idx) {
        const _sentEarnMore = resp => {
          this.sendEmailToBroker(idx + 1);
        };

        if (idx < this.accountearnmore.length) {
          const em = this.accountearnmore[idx];
          if (em.selected) {
            let json_data = Object.assign({}, this.accountearnmore[idx]);
            json_data['userid'] = user_data.email;
            json_data['name'] = user_data['user_metadata']['first_name'];
            if (this.adminusr) {
              json_data['name'] = user_data.email;
            }
            this.atleastone = true;
            this.$sendEarnMore(json_data).then(_sentEarnMore, this.failopSenteEarnMore);
          }
          else {
            _sentEarnMore();
          }
        }
        else {
          this.modalinprogress = false;
          if (!this.atleastone) {
            this.$toast.warning(this.$t('helper_allocationmethodtxt'));
          }
          else {
            swal(this.$t('infotxt'), this.$t('earnmoretxtsuccess'), "info")
            setTimeout(this.closeModalEarn, timercountdown * 1.5);
          }
        }
      },
    },
    mounted () {
      this.inprogress = true;
      // Country Code Phones
      const _tmp = this.$country_phone_list();
      const _tmpkeys = Object.keys(_tmp);
      for(var i=0;i < _tmpkeys.length;i++) {
        const key = _tmpkeys[i];
        let nuk = key +"_"+ _tmp[key];
        this.countryphones.push({
          'code': nuk, 'name': key +" +"+ _tmp[key]
        });
      }
      // User Metadata
      this.umeta = Object.assign({}, user_data.user_metadata);
      if (this.umeta.coutryph !== undefined && this.umeta.countryph !== null) {
        this.mycountryph = this.umeta.countryph;
      }
      // Get VPS Stats
      // Get Wallet Projection
      this.$getWalletProjection(user_data.email)
        .then(this.loadProjections, this.loadFail);


      // Get User Info
      this.$getUserInfo(user_data.email)
        .then(this.loadDashMsg, this.loadFail);

      this.hide_free_replication = rules.hide_free_replication
      this.hide_premium = rules.hide_premium
    },
    watch: {
      'modalnotice': function (value) {
        if (!this.modalnotice) {
          Vue.$sessionStorage.clear();
        }

      }
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    }
  }

</script>
<style lang="scss">
  @media screen and (min-width: 768px) {
    #freeTradeReplication {
      min-width: 620px !important;
    }
  }
  .bit-smaller {
    position: absolute !important;
    top: -5px;
    font-size: 12px !important;
    height: 2rem !important;
    width: 2rem !important;
    min-width: 2rem !important;
  }
  div.brokerlogo {
    text-align: center;
    position: relative;
    padding: 10px 10px 10px 3px;
  }
  div.brokerlogo img  {
    border-radius: 50%;
    width: 90px;
    height: 90px;
    object-fit: contain;
    border: 1px solid silver;
    cursor: pointer;
  }
  div.brokerlogo img:hover  {
    border: 3px solid #216b6b;
  }
  .table {
    margin-bottom: 0;
  }
  .table-responsive {
    overflow: hidden;
  }

  .earn-more {
    top: -10px;
  }

  .earn-more-broker-logo {
    float: left;
    position: relative;
    padding: 10px 10px 10px 3px;
  }

  .earn-more-broker-logo img {
    border-radius: 50%;
    width: 35px;
    border: 1px solid silver;
  }
</style>
